.App {
  text-align: center;

}
.Header {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  text-align: center;
}

h1 {
  text-align: center;
  font-size: 6em;
  margin: 0em;
  padding: 0em;
}

code{
  text-align: left;
  display: block;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-size: 1.37em;
}


